import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import assets from "../assets";
import { Colors, FontSize, Fontfamily, FontWeight } from "../common";

const features = [
  {
    icon: assets.innovativeSol,
    title: "Innovative Solutions",
    description:
      "Pioneering the Future of Technology with Cutting-Edge, Customized Solutions.",
  },
  {
    icon: assets.experienceandexpertise,
    title: "Expertise and Experience",
    description:
      "Over 20 Years of Proven Success in Delivering Top-Tier IT Services.",
  },
  {
    icon: assets.globallocaltouch,
    title: "Global Reach, Local Touch",
    description: "Providing Global IT Expertise with a Personal Touch.",
  },
  {
    icon: assets.securityfocused,
    title: "Security Focused",
    description:
      "Leading the Industry with Unrivaled Cybersecurity Measures to Protect Your Business.",
  },
  {
    icon: assets.innovativetech,
    title: "Innovative Technologies",
    description:
      "Harnessing the Power of AI and Machine Learning to Drive Business Efficiency.",
  },
  {
    icon: assets.rapidresponse,
    title: "Rapid Response Time",
    description:
      "24/7 Support with Immediate Response to Keep Your Operations Running Smoothly.",
  },
];

const OurFeatures = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    sx={{ backgroundColor: "#f9fafa", mb: 4,  pt: 10 }}
    >
    <Typography
      variant="h4"
      sx={{
        textAlign: "center",
        letterSpacing: "1.5px",
        fontWeight: FontWeight.bold,
        color: Colors.charcoalgray,
        fontSize: FontSize.thirty,
        fontFamily: Fontfamily.poppins,
      }}
      gutterBottom
    >
      We Empower Your Business
    </Typography>

    <Typography
      paragraph
      sx={{
        // mb: 3,
        ml: 2,
        mr: 2,
        textAlign: "center",
        // width: isMobile ? "100%" : "100%",
        color: Colors.MediumGray,
        fontFamily: Fontfamily.poppins,
        letterSpacing: "1.5px",
      }}
    >
      As a tech partner, we are focused on empowering your business and
      promise seamless experience with.
    </Typography>
    <Grid container spacing={4}  sx={{ p: {xs: 3, md: 5} }}>
      {features.map((feature, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
        >
          <Card
            sx={{
              height: "100%",
              borderRadius: "10px",
              transition: "transform 0.5s",
              "&:hover": {
                transform: "translateY(-12px)",
              },
            }}
          >
            <CardContent sx={{ mt: { xs: 0, md: 1, lg: 2 } }}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  sx={{
                    color: Colors.AtomicTangerine,
                  }}
                >
                  <img
                    src={feature.icon}
                    alt={feature.title}
                    style={{ width: "50px", height: "50px" }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 0,
                      mb: 0.5,
                      fontWeight: FontWeight.bold,
                      colors: Colors.charcoalgray,
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1.5px",
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: Colors.MediumGray,
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1px",
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
  );
};

export default OurFeatures;
