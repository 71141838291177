// import React from "react";
// import { Box } from "@mui/material";
// import { Grid } from "@mui/material";
// import { Typography } from "@mui/material";
// import { Colors, FontSize, Fontfamily, FontWeight } from "../common";

// const Header = (props) => {
//   let { title, description, image } = props;
//   return (
//     <>
//       <Box
//         sx={{
//           backgroundImage: `url(${image})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundAttachment: { xs: "scroll", md: "fixed" },
//           // backgroundAttachment: "fixed",
//           height: { xs: "980px", md: "920px" },
//           position: "relative", 
//         }}
//       >
//         <Box
//           sx={{
//             width: "100%",
//             height: "100%",
//             position: "absolute",
//             backgroundColor: "rgba(0, 0, 0, 0.4)",
//           }}
//         />
//         <Grid
//           container
//           sx={{
//             pt: { xs: 35, md: 45 },
//             px: { xs: 2, md: 5 },
//             color: "#fff",
//             opacity: 1,
//             position: "relative",
//           }}
//         >
//           <Typography
//             sx={{
//               width: "100%",
//               textAlign: "center",
//               fontWeight: "900",
//               fontSize: { xs: "40px", sm: "40px", md: "80px" },
//               fontFamily: Fontfamily.playfairDisplay,
//               letterSpacing: "3.8px",
//             }}
//           >
//             {title}
//           </Typography>
//           <Typography
//             sx={{
//               fontSize: { xs: "17px", sm: "18px", md: "22px", lg: "25px" },
//               lineHeight: "2.0",
//               textAlign: "center",
//               fontWeight: "bold",
//               letterSpacing: { xs: "1.5px", md: "2.5px" },
//               pt: 2,
//               m: { xs: 0, md: -1 },
//               fontFamily: Fontfamily.playfairDisplay,
//             }}
//           >
//             {description}
//           </Typography>
//         </Grid>
//       </Box>
//     </>
//   );
// };

// export default Header;

import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Colors, FontSize, Fontfamily, FontWeight } from "../common";

const Header = (props) => {
  const { title, description, image, placeholder } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: isLoaded
            ? `url(${image})`
            : `url(${placeholder})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: { xs: "scroll", md: "fixed" },
          height: { xs: "980px", md: "980px" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        />
        <Grid
          container
          sx={{
            pt: { xs: 30, md: 45 },
            px: { xs: 2, md: 5 },
            color: "#fff",
            opacity: 1,
            position: "relative",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: "900",
              fontSize: { xs: "40px", sm: "40px", md: "80px" },
              fontFamily: Fontfamily.playfairDisplay,
              letterSpacing: "3.8px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "17px", sm: "18px", md: "22px", lg: "25px" },
              lineHeight: "2.0",
              textAlign: "center",
              fontWeight: "bold",
              letterSpacing: { xs: "1.5px", md: "2.5px" },
              pt: 2,
              m: { xs: 0, md: -1 },
              fontFamily: Fontfamily.playfairDisplay,
            }}
          >
            {description}
          </Typography>
        </Grid>
        <img
          src={image}
          alt="background"
          style={{ display: "none" }}
          onLoad={handleImageLoad}
        />
      </Box>
    </>
  );
};

export default Header;


