// export default {
//   roboto: "Roboto, sans-serif",
//   openSans: "Open Sans, sans-serif",
//   lato: "Lato, sans-serif",
//   montserrat: "Montserrat, sans-serif",
//   oswald: "Oswald, sans-serif",
//   raleway: "Raleway, sans-serif",
//   poppins: "Poppins, sans-serif",
//   sourceSansPro: "Source Sans Pro, sans-serif",
//   nunito: "Nunito, sans-serif",
//   playfairDisplay: "Playfair Display, serif",
//   merriweather: "Merriweather, serif",
//   lora: "Lora, serif",
//   josefinSans: "Josefin Sans, sans-serif",
//   bebasNeue: "Bebas Neue, sans-serif",
//   comfortaa: "Comfortaa, sans-serif",
//   cabin: "Cabin, sans-serif",
//   crimsonText: "Crimson Text, serif",
//   droidSerif: "Droid Serif, serif",
//   firaSans: "Fira Sans, sans-serif",
//   heebo: "Heebo, sans-serif",
//   inriaSerif: "Inria Serif, serif",
// };
const Fontfamily = {
  roboto: "Roboto, sans-serif",
  openSans: "Open Sans, sans-serif",
  lato: "Lato, sans-serif",
  montserrat: "Montserrat, sans-serif",
  oswald: "Oswald, sans-serif",
  raleway: "Raleway, sans-serif",
  poppins: "Poppins, sans-serif",
  sourceSansPro: "Source Sans Pro, sans-serif",
  nunito: "Nunito, sans-serif",
  playfairDisplay: "Playfair Display, serif",
  merriweather: "Merriweather, serif",
  lora: "Lora, serif",
  josefinSans: "Josefin Sans, sans-serif",
  bebasNeue: "Bebas Neue, sans-serif",
  comfortaa: "Comfortaa, sans-serif",
  cabin: "Cabin, sans-serif",
  crimsonText: "Crimson Text, serif",
  droidSerif: "Droid Serif, serif",
  firaSans: "Fira Sans, sans-serif",
  heebo: "Heebo, sans-serif",
  inriaSerif: "Inria Serif, serif",
};
export default Fontfamily;