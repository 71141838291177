import React, { useState } from "react";
import { Button as TalkButton, Modal, message } from "antd";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import "../stylesheets/ModelLetsTalk.css";
import axios from "../axiosInstance";
import assets from "../assets";

const ModelLetsTalk = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    countryCode: "91",
    mobile: "",
    mode: "",
    messages: "",
  });
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`/api/v1/email/send-lets-talk-email`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      message.success(
        "Thanks for contacting us. We'll get back to you as soon as possible."
      );
      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        countryCode: "91",
        mobile: "",
        mode: "",
        messages: "",
      });
      setIsModalOpen(false);
    } catch (error) {
      // console.error("There was an error sending the email!", error);
      message.error("There was an error sending the email!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box className="letstalkmainbox">
        <TalkButton className="responsive-button" onClick={showModal}>
          Let's Talk
        </TalkButton>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={1500}
        >
          <Box>
            <Box sx={{ flexGrow: 0, mt: 5 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="form-container">
                    <form onSubmit={handleSubmit}>
                      <Box className="form-group">
                        <TextField
                          type="text"
                          id="firstname"
                          variant="outlined"
                          name="firstname"
                          label="First Name"
                          required
                          className="wide-input"
                          value={formData.firstname}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#f68f5c",
                              },
                            },
                          }}
                          InputProps={{
                            sx: {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#f68f5c",
                                },
                              "&:hover .MuiOutlinedInput": {
                                borderColor: "gray",
                              },
                            },
                          }}
                        />
                      </Box>

                      <Box className="form-group">
                        <TextField
                          type="text"
                          id="lastname"
                          variant="outlined"
                          name="lastname"
                          label="Last Name"
                          required
                          className="wide-input"
                          value={formData.lastname}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#f68f5c",
                              },
                            },
                          }}
                          InputProps={{
                            sx: {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#f68f5c",
                                },
                              "&:hover .MuiOutlinedInput": {
                                borderColor: "gray",
                              },
                            },
                          }}
                        />
                      </Box>

                      <Box className="form-group">
                        <TextField
                          type="email"
                          id="email"
                          variant="outlined"
                          name="email"
                          label="Email"
                          required
                          className="wide-input"
                          value={formData.email}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#f68f5c",
                              },
                            },
                          }}
                          InputProps={{
                            sx: {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#f68f5c",
                                },
                              "&:hover .MuiOutlinedInput": {
                                borderColor: "gray",
                              },
                            },
                          }}
                        />
                      </Box>

                      <Box
                        className="mobile"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Box
                          className="form-group"
                          style={{ flex: "0 1 100px" }}
                        >
                          <TextField
                            style={{ marginRight: "8px" }}
                            id="country-code"
                            name="countryCode"
                            label="Country Code"
                            variant="outlined"
                            required
                            value={formData.countryCode}
                            onChange={handleChange}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#f68f5c",
                                },
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +
                                </InputAdornment>
                              ),
                              sx: {
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#f68f5c",
                                  },
                                "&:hover .MuiOutlinedInput": {
                                  borderColor: "gray",
                                },
                              },
                            }}
                            inputProps={{ pattern: "[0-9]{1,3}" }}
                          />
                        </Box>

                        <Box className="form-group" style={{ flex: "1" }}>
                          <TextField
                            style={{
                              height: "39px",
                            }}
                            // type="tel"
                            id="mobile"
                            name="mobile"
                            pattern="[0-9]{10}"
                            label="Mobile Number"
                            variant="outlined"
                            fullWidth
                            type='number'
                            className="wide-input"
                            value={formData.mobile}
                            onChange={handleChange}
                            InputLabelProps={{
                      
                              sx: {
                                "&.Mui-focused": {
                                  color: "#f68f5c",

                                },
                              },
                            }}
                            InputProps={{
                              sx: {
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#f68f5c",
                                  },
                                "&:hover .MuiOutlinedInput": {
                                  borderColor: "gray",
                                },
                              },
                            }}
                          />
                        </Box>
                      </Box>

                      <Box className="form-group">
                        <FormControl fullWidth className="wide-input">
                          <InputLabel
                            id="mode-label"
                            sx={{
                              "&.Mui-focused": {
                                color: "#f68f5c",
                              },
                            }}
                          >
                            Mode of Communication
                          </InputLabel>
                          <Select
                            labelId="mode-label"
                            id="mode"
                            name="mode"
                            variant="outlined"
                            label="Mode of Communication"
                            required
                            defaultValue=""
                            fullWidth
                            value={formData.mode}
                            onChange={handleChange}
                            sx={{
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#f68f5c",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "gray",
                              },
                            }}
                          >
                            <MenuItem value="email">Email</MenuItem>
                            <MenuItem value="phone">Phone</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      <Box className="form-group">
                        <TextField
                          id="messages"
                          name="messages"
                          label="Messages"
                          multiline
                          rows={4}
                          className="wide-input"
                          variant="outlined"
                          fullWidth
                          required
                          value={formData.messages}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#f68f5c",
                              },
                            },
                          }}
                          InputProps={{
                            sx: {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#f68f5c",
                                },
                              "&:hover .MuiOutlinedInput": {
                                borderColor: "gray",
                              },
                            },
                          }}
                        />
                      </Box>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Inquire Now"}
                      </Button>
                    </form>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={assets.letsTalk}
                    alt="Description pic"
                    sx={{
                      width: "100%",
                      height: { xs: "auto", sm: "790px" },
                      backgroundPosition: "center",
                    }}
                  ></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default ModelLetsTalk;
