import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
 import Blogimage from "../assets/images/man-work.jpg"
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Typography from "@mui/material/Typography";
import { Box, Grid, Link as MuiLink } from "@mui/material";
import PageTitle from '../Components/PageTitle';
import { client } from "../Contentful/Client";
import moment from "moment";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Colors } from "../common";
import Navbar from "./Navbar";
import Footer from "./Footer";

const BlogPost = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await client.getEntries({ content_type: "blog" });
        // console.log(entries, "entries");
        setBlogs(entries.items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchData();
  }, [loading]);
  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <Box>
    <PageTitle title="GatikAi Blogs page" />
    <Navbar />
    <Header
      title="Our insights into technology and culture"
      image={Blogimage}
    />
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' , ml:3,mr:3 ,}}>
      <Grid container spacing={{ xs: 0, md: 1, lg: 2 }} justifyContent="center">
        {blogs?.map((item, index) => {
          const formattedDate = moment(item?.fields?.blogPostDate).format('MMMM DD, YYYY');
          return (
            <Grid item xs={12} md={12} lg={12} key={index}>
              <Box sx={{ mb: 5, mt: 5, display: 'flex', justifyContent: 'center' }}>
                <Card sx={{ maxWidth: 750 ,
                  borderBottom:"5px solid #f4813f",
                  borderRadius: '2px', 
                  boxShadow: '0 1px 40px rgba(0, 0, 0, 0.1)', 
                  overflow: 'hidden', 
       }}>
                  <CardHeader
                    title={item?.fields?.blogTitle}
                    subheader={formattedDate}
                    sx={{ padding: '16px' }}
                  />
                  <Link to={`/blogs/${item.fields?.blogId}`} style={{ textDecoration: 'none' }}>
                    <Box sx={{ overflow: 'hidden' }}>
                      <CardMedia
                        component="img"
                        height="500"
                        image={item?.fields?.blogImage?.fields?.file?.url}
                        alt={item?.fields?.blogImage?.fields?.file?.title}
                        sx={{
                          transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.1)',
                            opacity: 0.9,
                          },
                        }}
                      />
                    </Box>
                  </Link>
                  <CardContent sx={{ padding: '16px' }}>
                    <Typography variant="h6" color="text.primary">
                      {item?.fields?.blogDisplayTitle}
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Box sx={{ textAlign: 'end' }}>
                      <MuiLink
                        component={Link}
                        to={`/blogs/${item.fields?.blogId}`}
                        underline="none"
                      >
                        <IconButton
                          sx={{
                            color: 'black',
                            '&:hover': { color: 'primary.main' },
                          }}
                        >
                          <ArrowForwardIcon sx={{ color: 'black' }} />
                        </IconButton>
                      </MuiLink>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
    <Footer />
  </Box>
  );
};

export default BlogPost;
