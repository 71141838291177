// export default {
//   BLACK: '#000000',
//   charcoalgray: "#191a20",
//   WHITE: '#ffffff',
//   DimGray:"#777",
//   MediumGray:"#7a7a7a",
//   DarkGray: "#333",
//   Darkshadeofgrey: "#1e1e1e",
//   AtomicTangerine: "#f68f5c",
//   BrightOrange: "#f4813f",
// };

const colors = {
  BLACK: '#000000',
  charcoalgray: "#191a20",
  WHITE: '#ffffff',
  DimGray:"#777",
  MediumGray:"#7a7a7a",
  DarkGray: "#333",
  Darkshadeofgrey: "#1e1e1e",
  AtomicTangerine: "#f68f5c",
  BrightOrange: "#f4813f",
};

export default colors;