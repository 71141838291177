import React from "react";
import { Box, Grid, Card, Typography, Icon } from "@mui/material";
import { Colors, FontSize, Fontfamily, FontWeight } from "../common";
import SchoolIcon from "@mui/icons-material/School";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PeopleIcon from "@mui/icons-material/People";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import FlightIcon from "@mui/icons-material/Flight";
import CommuteIcon from "@mui/icons-material/Commute";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FastfoodIcon from "@mui/icons-material/Fastfood";

const IndustriesCards = [
  {
    id: 1,
    Logoicon: <SchoolIcon />,
    industriescardHeading: "Education",
    industriescardPara:
      "We revolutionize education with AI-driven solutions that enhance learning experiences and improve outcomes for students and educators alike.",
  },
  {
    id: 2,
    Logoicon: <FitnessCenterIcon />,
    industriescardHeading: "Health & Fitness",
    industriescardPara:
      "Our AI technology helps individuals and organizations achieve their health and fitness goals through personalized plans and real-time analytics.",
  },
  {
    id: 3,
    Logoicon: <PeopleIcon />,
    industriescardHeading: "Social Networking",
    industriescardPara:
      "We create intelligent social networking platforms that foster connections and engagement through advanced AI algorithms.",
  },
  {
    id: 4,
    Logoicon: <LiveTvIcon />,
    industriescardHeading: "OTT & VOD",
    industriescardPara:
      "Our AI solutions transform OTT and VOD services, offering personalized content recommendations and enhancing viewer experiences.",
  },
  {
    id: 5,
    Logoicon: <FlightIcon />,
    industriescardHeading: "Travel & Tourism",
    industriescardPara:
      "We enhance travel experiences with AI-driven insights, personalized recommendations, and efficient itinerary planning.",
  },
  {
    id: 6,
    Logoicon: <CommuteIcon />,
    industriescardHeading: "Transport",
    industriescardPara:
      "Our AI solutions improve transportation systems with real-time data analytics, optimizing routes and reducing costs.",
  },
  {
    id: 7,
    Logoicon: <ShoppingCartIcon />,
    industriescardHeading: "E-commerce",
    industriescardPara:
      "We boost e-commerce businesses with AI-powered tools for personalized shopping experiences, inventory management, and customer insights.",
  },
  {
    id: 8,
    Logoicon: <FastfoodIcon />,
    industriescardHeading: "Food and Grocery",
    industriescardPara:
      "Our AI technology helps food and grocery businesses optimize supply chains, reduce waste, and enhance customer experiences.",
  },
];

const IndustriesServe = () => {
  return (
      <>
        <Typography
          sx={{
            textAlign: "center",
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            fontSize: FontSize.thirty,
            color: Colors.charcoalgray,
            fontFamily: Fontfamily.poppins,
          }}
        >
          Diverse Industries we work with
        </Typography>
        <Typography
          paragraph
          sx={{
            mt: 1,
            mb: 2,
            pl:1,
            pr:1,
            textAlign: "center",
            letterSpacing: "1.5px",
            color: Colors.MediumGray,
            fontFamily: Fontfamily.poppins,
          }}
        >
          At Gatik AI, we're proud to work with a wide range of industries, each
          with its unique challenges and opportunities.
        </Typography>
        <Grid container spacing={4} sx={{ p: {xs: 3, md: 5} }}>
        {IndustriesCards.map((item) => (
          <Grid
            item
            spacing={4}
            xs={12}
            sm={6}
            md={3}
            lg={3}
            key={item.id}
            sx={{ mb: 5 }}
          >
            <Card
              sx={{
                boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.15)",
                padding: "20px",
                height: "100%",
                borderRadius: "10px",
                backgroundColor: "#FFF",
                transition: "transform 0.5s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  backgroundColor: "#f9fafa",

                },
              }}
            >
              <Icon
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "#f68f5c",
                  color: Colors.WHITE,
                  padding: "20px",
                }}
              >
                {item.Logoicon}
              </Icon>
              <Typography
                variant="h5"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontWeight: FontWeight.heading2,
                  fontSize: FontSize.twFour,
                  color: Colors.charcoalgray, 
                  letterSpacing: "1.5px",
                }}
              >
                {item.industriescardHeading}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  lineHeight: "1.6rem",
                  letterSpacing: "1px",
                  marginTop: "10px",
                  color: Colors.MediumGray,
                  fontFamily: Fontfamily.poppins,
                }}
              >
                {item.industriescardPara}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      </>
  );
};

export default IndustriesServe;
