import React from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import assets from "../assets";
import { Colors , FontSize , Fontfamily , FontWeight } from "../common";
import { Link } from "react-router-dom";

const expertiseData = [
  { icon: assets.CloudImage, label: "Web / Mobile" },
  { icon: assets.IoTImage, label: "IoT" },
  { icon: assets.AiMiImage, label: "AI / ML" },
  { icon: assets.UXImage, label: "UI / UX" },
  { icon: assets.DevOpsImage, label: "DevOps" },
];

const DesignDevelopment = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        py: 4,
        pt: 10,
        pb:5,
        mb: 5,
        backgroundColor: "#f9fafa",
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          textAlign: "center",
          letterSpacing: '1.5px',
          fontWeight: FontWeight.bold,
          fontSize: FontSize.thirty,
          color: Colors.charcoalgray,
          fontFamily: Fontfamily.poppins
        }}
      >
        EXPERTISE
      </Typography>
     
      <Grid container spacing={2} columnSpacing={1} rowSpacing={5} justifyContent="center" sx={{ mt: 2 }}>
        {expertiseData.map((item, index) => (
          <Grid item spacing={1} xs={6} sm={4} md={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "50%",
                mb:5
              }}
            >
              <img src={item.icon} alt="service icon" />
              <Typography variant="h6" sx={{ fontSize: FontSize.twFour, letterSpacing: '1px', pl:2, pr:2 }}>
                {item.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button
      variant="outlined"
        sx={{
          mt: 4,
          color: Colors.DarkGray,
          // backgroundColor: "#f68f5c",
         border: "1px solid #333",
          "&:hover": { backgroundColor: "#fd7e14", color: Colors.WHITE,   border: "1px solid #f68f5c" },
        }}
      >
        <Link
          to={"/services"}
          style={{ color: "inherit", textDecoration: "none", letterSpacing: '2px' }}
        >
          {" "}
          EXPLORE
        </Link>
      </Button>
    </Box>
  );
};

export default DesignDevelopment;
