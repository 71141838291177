import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { message } from "antd";
import axios from "../axiosInstance";
import assets from "../assets/images/footer-bg.png";
import { FontWeight , FontSize , Colors , Fontfamily } from "../common";
import PageTitle from '../Components/PageTitle';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function Career() {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    messages: "",
    file: null,
  });

  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormValues({ ...formValues, file });
    setFileName(file ? file.name : "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", formValues.name);
    formData.append("email", formValues.email);
    formData.append("phone", formValues.phone);
    formData.append("messages", formValues.messages);
    formData.append("file", formValues.file);
    try {
      const response = await axios.post(
        "/api/v1/file/send-job-email",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(
        "Thank you for your application! We're excited to review it and will be in touch shortly."
      );
      setFormValues({
        name: "",
        email: "",
        phone: "",
        messages: "",
        file: null,
      });
      setFileName("");
    } catch (error) {
      message.error("There was an error sending the email!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Navbar />
    <PageTitle title="Join Our Team | GatikAI Tech" />
    <Box
      sx={{
        backgroundImage: `url(${assets})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
      }}
     >
      <Container>
        <Grid container spacing={2} sx={{ minHeight: "100vh" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFF",
              order: { xs: 2, md: 1 },
              mt: { xs: 0, md: 18 },
            }}
          >
            <Box sx={{ p: 3 }}>
              <Typography
                sx={{
                  fontWeight: FontWeight.heading2,
                  fontSize: FontSize.fourty,
                  fontFamily: Fontfamily.inriaSerif,
                  letterSpacing: "1.5px",
                }}
                gutterBottom
              >
                We Are On A Hunt For Talent!
              </Typography>
              <Typography variant="body1" sx={{letterSpacing: "1px", fontFamily: Fontfamily.poppins, fontSize:FontSize.eighteen}}>
                Join our team at GatikAI Technologies and be a part of a
                dynamic, innovative environment where your ideas and skills can
                flourish. Embark on a rewarding career journey with us and make
                a difference in the tech world.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              order: { xs: 1, md: 2 },
              mt: { xs: 15, md: 18 },
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 3,
                bgcolor: "background.paper",
                boxShadow: 1,
                mb: { xs: 5, md: 5 }, 
                borderRadius: 2,
                width: "100%",
                maxWidth: 500,
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: FontWeight.bold,
                  fontSize:FontSize.twFour,
                  letterSpacing: "1.5px"
                }}
                gutterBottom
              >
                Share Your Resume with us!
              </Typography>
              <TextField
                label="Name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                required
                value={formValues.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Phone Number"
                name="phone"
                type='number'
                required
                value={formValues.phone}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Write your Skill set"
                name="messages"
                value={formValues.messages}
                onChange={handleChange}
                multiline
                required
                rows={4}
                fullWidth
                margin="normal"
              />
              <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 1 }}>
                <Button variant="outlined" component="label">
                  Choose File
                  <input
                    type="file"
                    hidden
                    name="file"
                    accept="application/pdf"
                    required
                    onChange={handleFileChange}
                  />
                </Button>
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {fileName}
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 2, mb: 2, letterSpacing: "1px" }}>
                Only Pdf Format Allowed
              </Typography>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{ mt: 2, letterSpacing: "1px" }}
                style={{ marginBottom: "5px" }}
                disabled={loading}
              >
                {loading ? "Submitting..." : "Send Now"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Footer />
    </>
  );
}

export default Career;
