import React from "react";
import { Box, Container, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { FontSize, FontWeight } from "../common";
import PageTitle from "../Components/PageTitle";

const GooglePrivacyAndPolicy = () => {
  return (
    <div>
      <Container>
        <PageTitle title="Privacy Policy for Gangs App" />
        <Box
          sx={{
            minHeight: "auto",
            // mt: 12,
            mb: 7,
            backgroundColor: "#f4f4f4",
            p: 5,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 5,
              mb: 5,
              fontWeight: FontWeight.bold,
            }}
          >
            Privacy Policy for Gangs App
          </Typography>
          <br />
          <Typography sx={{ lineHeight: "1.7" }}>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy.
          </Typography>

          <Typography
            variant="h4"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 5,
              mb: 5,
              fontWeight: FontWeight.bold,
            }}
          >
            Interpretation and Definitions
          </Typography>

          <Typography
            variant="h5"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 5,
              mb: 5,
              fontWeight: FontWeight.bold,
            }}
          >
            Interpretation
          </Typography>

          <Typography sx={{ lineHeight: "1.7" }}>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 5,
              mb: 5,
              fontWeight: FontWeight.bold,
            }}
          >
            Definitions
          </Typography>

          <Typography sx={{ lineHeight: "1.7" }}>
            For the purposes of this Privacy Policy:
          </Typography>

          <Box className="TypographyBox" sx={{ mt: 3 }}>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Account</b> means a unique account created for You to access
              our Service or parts of our Service.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Affiliate</b> means an entity that controls, is controlled by
              or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other
              managing authority.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Application</b> refers to Gangs, the software program provided
              by the Company.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Company</b> (referred to as either "the Company", "We", "Us" or
              "Our" in this Agreement) refers to GatikAI Technologies OPC Ltd.,
              306A, ARG North Avenue, Road 9, Sikar Road, Jaipur (Raj), India.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Country</b> refers to: Rajasthan, India
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Device </b> means any device that can access the Service such
              as a computer, a cellphone or a digital tablet.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Personal Data</b> is any information that relates to an
              identified or identifiable individual.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Service</b> refers to the Website.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Service Provider </b> means any natural or legal person who
              processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>Usage Data</b> refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>You</b> means the individual accessing or using the Service, or
              the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </Typography>

            <Typography
              variant="h4"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Collecting and Using Your Personal Data
            </Typography>

            <Typography
              variant="h5"
              sx={{ mt: 2, fontWeight: FontWeight.bold }}
            >
              Types of Data Collected
            </Typography>

            <Typography
              variant="h6"
              sx={{ mt: 2, fontWeight: FontWeight.bold }}
            >
              Personal Data
            </Typography>

            <Typography variant="body1" sx={{ mt: 2, lineHeight: "1.7" }}>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Email address
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              First name and last name
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Phone number
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Usage Data
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 2, lineHeight: "1.7" }}
              gutterBottom
            >
              Usage Data is collected automatically when using the Service.
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 2, lineHeight: "1.7" }}
              gutterBottom
            >
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 2, lineHeight: "1.7" }}
              gutterBottom
            >
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 2, lineHeight: "1.7" }}
              gutterBottom
            >
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </Typography>

            <Typography
              variant="h5"
              sx={{ fontWeight: FontWeight.bold, mt: 2 }}
            >
              Information Collected while Using the Application
            </Typography>

            <Typography
              variant="body1"
              sx={{ mt: 2, lineHeight: "1.7" }}
              gutterBottom
            >
              While using Our Application, in order to provide features of Our
              Application, We may collect, with Your prior permission:
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Pictures and other information from your Device's camera and photo
              library
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, lineHeight: "1.7" }}>
              We use this information to provide features of Our Service, to
              improve and customize Our Service. The information may be uploaded
              to the Company's servers and/or a Service Provider's server or it
              may be simply stored on Your device.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, lineHeight: "1.7" }}>
              You can enable or disable access to this information at any time,
              through Your Device settings.
            </Typography>

            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Use of Your Personal Data
            </Typography>

            <Typography variant="body1" sx={{ mt: 2, lineHeight: "1.7" }}>
              The Company may use Personal Data for the following purposes:
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>To provide and maintain our Service:</b> including to monitor
              the usage of our Service.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>To manage Your Account:</b> to manage Your registration as a
              user of the Service. The Personal Data You provide can give You
              access to different functionalities of the Service that are
              available to You as a registered user.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>For the performance of a contract:</b> the development,
              compliance and undertaking of the purchase contract for the
              products, items or services You have purchased or of any other
              contract with Us through the Service.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>To contact You:</b>To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such
              as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>To provide You:</b>with news, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless You have opted not to receive such
              information.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>To manage Your requests:</b>To attend and manage Your requests
              to Us.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>For business transfers:</b>We may use Your information to
              evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of Our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which Personal
              Data held by Us about our Service users is among the assets
              transferred.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>For other purposes:</b>We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              We may share Your personal information in the following
              situations:
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>With Service Providers:</b>We may share Your personal
              information with Service Providers to monitor and analyze the use
              of our Service, to contact You.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>For business transfers:</b>We may share or transfer Your
              personal information in connection with, or during negotiations
              of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of Our business to another company.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>With Affiliates:</b>We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>With business partners:</b>We may share Your information with
              Our business partners to offer You certain products, services or
              promotions.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>With other users:</b>when You share personal information or
              otherwise interact in the public areas with other users, such
              information may be viewed by all users and may be publicly
              distributed outside.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              <b>With Your consent:</b>We may disclose Your personal information
              for any other purpose with Your consent.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Retention of Your Personal Data
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Transfer of Your Personal Data
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Delete Your Personal Data
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Our Service may give You the ability to delete certain information
              about You from within the Service.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </Typography>

            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Disclosure of Your Personal Data
            </Typography>

            <Typography
              variant="h6"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Business Transactions
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Law enforcement
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </Typography>

            <Typography
              variant="h6"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Law enforcement
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </Typography>

            <Typography
              variant="h6"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Other legal requirements
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Comply with a legal obligation
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Protect and defend the rights or property of the Company
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Protect the personal safety of Users of the Service or the public
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              Protect against legal liability
            </Typography>

            <Typography
              variant="h6"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Security of Your Personal Data
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </Typography>

            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Children's Privacy
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </Typography>

            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Links to Other Websites
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </Typography>

            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 5,
                mb: 5,
                fontWeight: FontWeight.bold,
              }}
            >
              Changes to this Privacy Policy
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </Typography>
            <Typography
              variant="h4"
              sx={{ mt: 2, fontWeight: FontWeight.bold }}
            >
              Contact Us
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, lineHeight: "1.7" }}>
              If you have any questions about this Privacy Policy, You can
              contact us:
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              By email: contact@gatiktech.com
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              <FiberManualRecordIcon
                sx={{ color: "#000", fontSize: FontSize.ten }}
              />{" "}
              By visiting this page on our website:{" "}
              <a href="https://gatiktech.com/contact">
                https://gatiktech.com/contact
              </a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default GooglePrivacyAndPolicy;
