// Home page blogs design 
import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { FontWeight , Fontfamily ,Colors , FontSize } from "../common";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const blogPosts = [
  {
    date: "March 19, 2024",
    title: "Protect Your Workplace from Cyber Attacks",
    author: "john",
    image:
      "https://tse1.mm.bing.net/th?id=OIP.lOH2FLWUnxWiRRTmmsL_nAHaGh&pid=Api&P=0&h=180",
  },
  {
    date: "March 17, 2024",
    title: "Tips to Protecting Your Business and Family",
    author: "smith",
    image:
      "https://tse1.mm.bing.net/th?id=OIP.lOH2FLWUnxWiRRTmmsL_nAHaGh&pid=Api&P=0&h=180",
  },
  {
    date: "March 15, 2024",
    title: "The security risks of changing package owners",
    author: "admin",
    image:
      "https://tse1.mm.bing.net/th?id=OIP.lOH2FLWUnxWiRRTmmsL_nAHaGh&pid=Api&P=0&h=180",
  },
];

const DateBox = styled(Box)({
  display: "flex",
  background: "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
  color: "#fff",
  padding: "12px 18px",
  borderRadius: "100px",
  marginBottom: "8px",
  marginLeft: "18px",
  width: "auto",
  float: "inline-start",
  position: "absolute",
  bottom: "-30px",
  zIndex: "100",
});

const Blog = () => {
  return (
    <Container>
      <Box textAlign="center" my={4} mt={12}>
        <Typography variant="h5" sx={{ fontWeight: FontWeight.bold }}>
          The News From Our Blog
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{mt:1}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {blogPosts.map((post, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} mb={5}>
            <Card sx={{ borderRadius: "10px" }}>
              <CardMedia
                component="img"
                height="200"
                image={post.image}
                alt={post.title}
              />
              <Box style={{ position: "relative" }}>
                <DateBox>
                  <CalendarTodayIcon fontSize="small" />
                  <Typography variant="body2" ml={1}>
                    {post.date}
                  </Typography>
                </DateBox>
              </Box>
              <CardContent sx={{ mt: 5 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: FontWeight.bold }}
                  component="h3"
                >
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" mb={2}>
                  by {post.author}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 2 }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida.
                </Typography>

                <Box style={{ display: "flex", alignItems: "center" }}>
                  <ArrowForwardIcon />
                  <Link
                    to={"/"}
                    style={{
                      color: "#000",
                      textDecoration: "none",
                      marginLeft: "4px",
                    }}
                  >
                    Read More
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Blog;
