import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent  from '@mui/material/CardContent';
import Container from "@mui/material/Container";
import { FontWeight, Fontfamily, FontSize, Colors } from "../common";
import { Button } from "antd";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import assets from "../assets";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Testseries() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", fontWeight: FontWeight.bold }}
        >
          Revolutionize Your Institute's Exam Management with Test Series!
        </Typography>
      </Box>
      {/* <Card sx={{ mt: 5, mb: 5 }}> */}
        <Typography  sx={{ textAlign: "center", p: 1, mt:4 }}>
          Introducing our comprehensive Test Series, designed to elevate your
          learning experience with a range of essential features. Access
          concise, well-organized notes that cover all key topics and receive
          insightful performance analysis and feedback through detailed exam
          reports. Learn from expert instructors via engaging and informative
          video lectures, and test your knowledge and skills with our
          user-friendly online test platform. Enhance your critical thinking and
          writing abilities with subjective exams, and master multiple-choice
          questions with our extensive MCQ exams. Enjoy a seamless and efficient
          study experience with well-organized and easily accessible data.
          Achieve your academic goals with our all-inclusive Test Series!
        </Typography>
      {/* </Card> */}
      <Box sx={{ mt: 4, mb: 3 }}>
        <Container sx={{ mt: 8 }}>
          <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
            <Grid item xs={12} md={4} lg={4}>
              <Box
                component="img"
                src={assets.testseries1}
                alt="Description pic"
                sx={{
                  width: "100%",
                  height: "350px",
                  borderRadius: "10px",
                  backgroundPosition: "center",
                }}
              ></Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              lg={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "5px",
                  marginTop: 1,
                  mb: { xs: 3, sm: 3, md: 5, lg: 5 },
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: FontSize.thirty,
                    color: Colors.charcoalgray,
                    fontWeight: FontWeight.bold,
                    fontFamily: Fontfamily.poppins,
                    marginBottom: "20px",
                    lineHeight: "1.4",
                  }}
                  gutterBottom
                >
                  The Smart Choice for Institutes!
                </Typography>
                <p
                  style={{
                    fontSize: FontSize.eighteen,
                    fontFamily: Fontfamily.poppins,
                    color: Colors.MediumGray,
                    lineHeight: "1.6",
                  }}
                >
                  Test Series empowers institute owners by streamlining the exam
                  preparation and management process. With features such as
                  customizable multiple-choice questions, time-limited exams,
                  and instant results, institute owners can provide an efficient
                  and effective learning experience. The platform allows for
                  easy role assignment to Professors, Teachers, and Heads of
                  Departments, ensuring smooth administration and oversight.
                  Students can sign up directly for their specific institution,
                  fostering a personalized educational environment. Additional
                  tools like job notifications, PDF note uploads, and course
                  videos ensure students have comprehensive resources for
                  success, all within a user-friendly interface.
                </p>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
            <Grid
              item
              xs={12}
              md={8}
              lg={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
               
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "5px",
                  marginTop: 1,
                  mb: { xs: 3, sm: 3, md: 5, lg: 5 },
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: "black",
                    mb: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: FontSize.thirty,
                    color: Colors.charcoalgray,
                    fontWeight: FontWeight.bold,
                    fontFamily: Fontfamily.poppins,
                    marginBottom: "20px",
                    lineHeight: "1.4",
                  }}
                  gutterBottom
                >
                  Your Ultimate Exam Prep Partner!
                </Typography>
                <p
                  style={{
                    fontSize: FontSize.eighteen,
                    fontFamily: Fontfamily.poppins,
                    color: Colors.MediumGray,
                    lineHeight: "1.6",
                  }}
                >
                  Test Series is designed to enhance the learning experience for
                  students by providing a comprehensive and efficient platform
                  for exam preparation. With customizable multiple-choice
                  questions and time-limited exams, students can practice under
                  real exam conditions, improving their time management skills.
                  Instant results and top rankers lists offer immediate feedback
                  and motivate students to excel. Students also benefit from job
                  notifications, keeping them informed about career
                  opportunities, and access to notes in PDF format for
                  convenient study. Additionally, the platform offers course
                  videos and lectures for on-the-go learning, making it easier
                  to grasp complex concepts. The user-friendly interface ensures
                  that students can navigate and utilize all these features
                  effortlessly, leading to a more productive and engaging
                  learning experience.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box
                component="img"
                src={assets.testseries2}
                alt="Description pic"
                sx={{
                  width: "100%",
                  height: "350px",
                  borderRadius: "10px",
                  backgroundPosition: "center",
                }}
              ></Box>
            </Grid>
          </Grid>

          <Box>
           <Card sx={{backgroundColor:"#f4f4f4" , mb:3,}}>
            <CardContent sx={{backgroundColor:"#f4f4f4"}}>
              <Typography variant="body1" >
              {/* <FiberManualRecordIcon sx={{ color: "#000", fontSize: "10px" }} /> */}
              <b>Note:</b> Contact us at testseries@gatiktech.com to request a free demo account.
              </Typography>
            </CardContent>
           </Card>
          </Box>
          <Box>
            <Button
            //   variant="contained"
              size="small"
              component="a"
              href="https://testseries.gatiktech.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="responsive-button"
              style={{textDecoration: "none", padding: "1.4rem", fontSize: "15px", letterSpacing: "2px"}}
            >
              See More
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
