import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import "../stylesheets/Workprocess.css";
import { Colors , FontSize , Fontfamily , FontWeight } from "../common";

import assets from "../assets";

const WorkProcessCards = [
  {
    imageSrc: assets.Idea,
    Workprocesstitle: "Get Ideas",
    Workprocessdescription: "Create your own style As need.",
  },
  {
    imageSrc: assets.Sketch,
    Workprocesstitle: "Sketch Up",
    Workprocessdescription: "Design is thinking Made visual.",
  },
  {
    imageSrc: assets.Meeting,
    Workprocesstitle: "Discuss",
    Workprocessdescription: "Together we can transform Design.",
  },
  {
    imageSrc: assets.Revision,
    Workprocesstitle: "Revise",
    Workprocessdescription: "Identify problems and give solutions.",
  },
  {
    imageSrc: assets.Approve,
    Workprocesstitle: "Approve",
    Workprocessdescription: "Completed Demonstrate and decide.",
  },
  {
    imageSrc: assets.Launch,
    Workprocesstitle: "Launch",
    Workprocessdescription: "The Way to get started To Express",
  },
];

const WorkProcess = () => {
  return (
      <Box className="workprocess-heading"  sx={{
        background: "#F68F5C",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        pb: 5,
        pt: 5,
        mt: 5,
      }}>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            fontSize: FontSize.twNine,
            color: Colors.WHITE,
            fontFamily:Fontfamily.poppins,
          }}
        >
          Work Process
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: Colors.WHITE,
            textAlign: "center",
            letterSpacing: "1.5px",
            pl:1,
            pr:1,
            lineHeight: "26px",
            fontWeight: FontWeight.heading4,
            fontSize: FontSize.fifteen,
            fontFamily:Fontfamily.poppins,  
            mt:1
          }}
        >
          We have continued to adapt ourselves to the changes within the digital
          industry and refined our processes to produce awesome web and mobile
          work for our clients.
        </Typography>

        <Container maxWidth="lg"  sx={{ pt: 3}} >
        <Grid
          container
          spacing={4}
          rowSpacing={3}
          columnSpacing={4}
          justifyContent="center"
          alignItems="center"
          >
          {WorkProcessCards.map((card, index) => (
            <Grid item key={index} >
              <Card
                className="WorkProcess-card"
                sx={{
                  background: "#fff",
                  borderRadius: "20px",
                  boxShadow: "10px 10px #F19D72",
                  textAlign: "center",
                  mt:4,
                  mb:4,
                  display: "flex",
                  justifyContent: "center",
                  transition: "all 0.5s ease",
                  height: "195px",
                  width: "165px",
                }}
              >
                <CardContent>
                  <img
                    alt={card.Workprocesstitle}
                    src={card.imageSrc}
                    width="40"
                    height="40"
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 2,
                      fontWeight:FontWeight.heading4,
                      color: Colors.Darkshadeofgrey,
                      display: "block",
                      fontSize: FontSize.seventeen,
                      letterSpacing: "1.5px",
                      mb: 2,
                    }}
                  >
                    {card.Workprocesstitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      color: Colors.DimGray,
                      display: "block",
                      fontSize: FontSize.fourteen,
                      fontWeight: FontWeight.heading5,
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1px",
                    }}
                  >
                    {card.Workprocessdescription}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </Box>
  );
};

export default WorkProcess;
