import React, { useState, useEffect } from "react";
import { getRichImage } from "./getRichImage";

const BlockAsset = ({ assetId }) => {
  const [imageUrl, setImageUrl] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const data = getRichImage(assetId).then((res) => {
      setImageUrl(res);
      setLoading(false);
    //   console.log(res);
    });
  }, []);

  if (isLoading) return <p>loading...</p>;
  if (!imageUrl) return <p>No image found!</p>;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <img src={imageUrl} width={750} height={480} alt="blog description img" style={{ margin: "30px" }} />
    </div>
  );
};

export default BlockAsset;
