import React from 'react';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';

const GatikApps = () => {
  return (
    <div>
      <Box
          sx={{
            mt: 5, 
            mb: 7,
            ml:2
          }}
        >
          <Typography
            variant="h4"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 5,  
              mb: 5,
              letterSpacing: "1.5px",
            }}
          >
            Download The Gangs App
          </Typography>

    <Stack direction="row" spacing={2} alignItems="center">
      <Link
        sx={{ mr: 2, color:"inherit"  }}
        href="https://apps.apple.com/in/app/dadu-app/id6504513572"
        target="_blank"
        rel="noopener"
        underline="hover"
      >
        <LinkIcon sx={{ verticalAlign: 'middle', color:"#1976d2", mr:1 }} />
        iOS App
      </Link>
      </Stack>
    <Stack direction="row"  spacing={2} alignItems="center" sx={{mt:2}}>
    <Link
        sx={{ mr: 2, color:"inherit" }}
        href="https://play.google.com/store/apps/details?id=com.mygangs"
        target="_blank"
        rel="noopener"
        underline="hover"

      >
        <LinkIcon sx={{ verticalAlign: 'middle', color:"#1976d2", mr:1 }} />
        Android App
      </Link>
   
    </Stack>
    </Box>
    </div>
  )
}

export default GatikApps