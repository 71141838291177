import { client } from "./Client";

export const getRichImage = async (assetId) => {
  try {
    var a = await client.getAsset(assetId);
    var url = a.fields.file.url;
    return url;
  } catch (err) {
    // console.log("error in get rich image", err);
  }
};
