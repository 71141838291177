import React, { useEffect, useState } from "react";
import {  Box } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import "../stylesheets/Footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
  
  const currentYear = new Date().getFullYear();
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      <Box className="footer-main-box" >
          <Grid container>
            <Grid item xs={12}>
              <Box className="footer-main-inside-box">
                <Box className="footer-text-and-logo-section" sx={{alignItems: "center", justifyContent: "center", display: "flex", letterSpacing: '1.5px', mb:5}}>
                <Box className="footer-text-1">
                    <Link to="/termsandconditions">Terms and Conditions</Link>
                    <Link to="/privacyandpolicy">Privacy Policy</Link>
                    <Link to="/career">Careers</Link>
                    {/* <Link>Cookies Policy</Link>
                    <Link>Sustainability</Link>
                    <Link>FAQs</Link> */}
                    <Link to="/contact">Contact</Link>
                  </Box>
                </Box>
                <Divider sx={{ backgroundColor: "#fff", height: "2px" }} />
                <Box className="footer-under-section">
                  <p>
                    &copy; {currentYear} Copyright by GatikAI Technologies. All rights
                    reserved.
                  </p>
                  <p>
                    Registered Office: 306A, ARG North Avenue, Road 9, Sikar Road, Jaipur (Raj), India 
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        <Box className="up-down-button">
          {isVisible && (
            <button onClick={scrollToTop} className="back-to-top-button">
              <KeyboardArrowUpIcon sx={{ fontSize: 30 }} />
            </button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Footer;
