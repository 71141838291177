
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import ModalLetsTalk from "../Components/ModalLetsTalk";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import assets from "../assets";
import "../stylesheets/Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  // const [productDropdownOpen, setProductDropdownOpen] = useState(false);

  const location = useLocation();

  const menus = ["home", "about", "services", "product", "blogs", "contact"];

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
      setScrolled(currentScrollPos > 120);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible]);

  const navbarStyleCon = {
    top: visible ? "0" : "-60px",
    transition: "top 0.9s, background-color 0.9s, box-shadow 0.9s",
    width: "100%",
    position: "fixed",
    zIndex: "100",
    backgroundColor: scrolled ? "#FFF" : "transparent",
    boxShadow: scrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
    display: "flex",
    height: "100px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    boxSizing: "border-box",
    top: 0,
  };

  const navbarStyle = {
    backgroundColor: "#FFF",
  };

  // style for nav-links color like home,about, contact etc
  const navbarLinkStyle = {
    color: scrolled ? "#000" : "#FFF",
  };



  // for mobile devices dropdown
  const navbarStyleMobile = {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  };

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  // const handleProductDropdownToggle = () => {
  //   setProductDropdownOpen(!productDropdownOpen);
  // };

  const productMenu = (
    <Menu>
      <Menu.Item>
        <Link
          to="/products/testseries"
          className={`nav-links ${
            location.pathname === "/product" ? "active" : ""
          }`}
        >
          Test Series
        </Link>
      </Menu.Item>
      {/* <Menu.Item>
        <Link
          to="/products/liveIt"
          className={`nav-links ${
            location.pathname === "/product2" ? "active" : ""
          }`}
        >
          Live it!
        </Link>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <>
      <Box
        className="header"
        style={
          menus?.includes(location?.pathname?.split("/")[1]) ||
          location?.pathname == "/"
            ? navbarStyleCon
            : navbarStyle
        }
      >
        <Box className="nav-logo-parent">
          <Link to="/">
            <img
              src={assets.Mainlogo}
              className="navbarresponsivelogo"
              alt="logo img"
            />
          </Link>
        </Box>
        <Box>
          <ul
            className="links"
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {menus.map((item, index) => {
              

              if (item === "product") {
                return (

                  <li
                    key={item}
                    className="nav-links-dropdown"
                    style={{
                      padding: 5,
                      margin: 5,
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  >
                    <Dropdown overlay={productMenu}>
                      <div
                        className={`nav-links ${
                          location.pathname.includes("/product") ? "active" : ""
                        }`}
                        style={
                          menus.includes(location.pathname.split("/")[1]) ||
                          location.pathname === "/"
                            ? navbarLinkStyle
                            : null
                        }
                      >
                        Product
                      </div>
                    </Dropdown>
                  </li>
                );
              }

              return (
                <li key={item}>
                  <Link
                    style={
                      menus?.includes(location?.pathname?.split("/")[1]) ||
                      location?.pathname == "/"
                        ? navbarLinkStyle
                        : null
                    }
                    to={index == 0 ? "/" : "/" + item}
                    className={`nav-links ${
                      location.pathname == (index == 0 ? "/" : "/" + item)
                        ? "active"
                        : ""
                    }`}
                  >
                    {item}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box className="toggle_btn" onClick={handleToggleClick}>
            {isOpen ? (
              <ClearIcon
                style={
                  menus?.includes(location?.pathname?.split("/")[1]) ||
                  location?.pathname == "/"
                    ? navbarLinkStyle
                    : null
                }
              />
            ) : (
              <MenuIcon
                style={
                  menus?.includes(location?.pathname?.split("/")[1]) ||
                  location?.pathname == "/"
                    ? navbarLinkStyle
                    : null
                }
              />
            )}
          </Box>

          <Box className="LetstalkModel">
            <ModalLetsTalk />
          </Box>
        </Box>
      </Box>

      <Box
        style={navbarStyleMobile}
        className={`dropdown_menu ${isOpen ? "open" : "false"}`}
      >
        <li>
          <Link
            to="/"
            className={`nav-links ${location.pathname === "/" ? "active" : ""}`}
            onClick={handleToggleClick}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={`nav-links ${
              location.pathname === "/about" ? "active" : ""
            }`}
            onClick={handleToggleClick}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/services"
            className={`nav-links ${
              location.pathname === "/services" ? "active" : ""
            }`}
            onClick={handleToggleClick}
          >
            Services
          </Link>
        </li>
        <li className="nav-links-dropdown" style={{ cursor: "pointer" }}>
          <Dropdown overlay={productMenu}>
            <div
              className={`nav-links ${
                location.pathname.includes("/product") ? "active" : ""
              }`}
              style={
                menus.includes(location.pathname.split("/")[1]) ||
                location.pathname === "/"
                  ? navbarLinkStyle
                  : null
              }
            >
              Product
            </div>
          </Dropdown>
        </li>

        {/* <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://blazingpulse.blogspot.com"
            className={`nav-links ${
              location.pathname === "/services" ? "active" : ""
            }`}
            onClick={handleToggleClick}
          >
            Blogs
          </a>
        </li> */}

        <li>
          <Link
            to="/blogs"
            className={`nav-links ${
              location.pathname === "/blogs" ? "active" : ""
            }`}
            
            onClick={handleToggleClick}
          >
            Blogs
          </Link>
        </li>

        <li>
          <Link
            to="/contact"
            className={`nav-links ${
              location.pathname === "/contact" ? "active" : ""
            }`}
            onClick={handleToggleClick}
          >
            Contact
          </Link>
        </li>
      </Box>
    </>
  );
};

export default Navbar;
