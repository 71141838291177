import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import { client } from "../Contentful/Client";
import RichText from "../Contentful/RichText";
import PageTitle from "../Components/PageTitle";
import Header from "../Components/Header";
import moment from "moment";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const SingleBlog = () => {
  const param = useParams();
  const id = param.id;

  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await client.getEntries({ content_type: "blog" });

        let a = entries.items;
        a.map((item) => {
          if (item.fields.blogId == id) {
            setBlog(item.fields);
          }
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchData();
  }, [loading]);

  if (loading) {
    return <p>Loading...</p>;
  }
  const formattedDate = moment(blog.blogPostDate).format("MMMM DD, YYYY");

  return (
    <>
      <Box>
        <Navbar />
        <Header
          //  style={{ height: "50vh" }}
          title={blog.blogTitle}
          image={blog.blogImage.fields.file.url}
        />
        <Container>
          <PageTitle title="GatikAi Blog Detail Page" />
          <Box sx={{ mt: 15, mb: 3 }}>
            <Card
              sx={{
                borderRadius: "2px",
                boxShadow: "0 1px 40px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CardContent>
                <Typography variant="h5">{blog.blogTitle}</Typography>

                <Typography variant="h6">{formattedDate}</Typography>
              </CardContent>
              <CardMedia
                component="img"
                height="500"
                image={blog.blogImage.fields.file.url}
                alt={blog.blogImage.fields.file.title}
              />
              <CardContent>
                <Box>
                  <RichText content={blog.blogDescription} />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default SingleBlog;
