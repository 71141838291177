import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import ContactImage from "../assets/images/contact.jpg";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { message } from "antd";
import { Colors, FontSize, FontWeight, Fontfamily } from "../common";
import axios from "../axiosInstance";
import Header from "../Components/Header";
import PageTitle from "../Components/PageTitle";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const contactData = {
      name,
      email,
      messages,
    };
    try {
      const response = await axios.post(
        "/api/v1/email/send-contact-email",
        contactData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      message.success(
        "Thanks for contacting us. We'll get back to you as soon as possible."
      );
      setName("");
      setEmail("");
      setMessages("");
    } catch (error) {
      message.error("There was an error sending the email!");
    } finally {
      setLoading(false);
    }
  };

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <>
      <PageTitle title="Contact | GatikAI Tech Website & App Development Company" />
<Navbar />
      <Header
        title="Get in Touch With Us"
        description="Whether you have questions, need support, or are interested in our services, we're here to help. Reach out to us via the contact form below, and our team will get back to you promptly. Let's work together to innovate and achieve your goals."
        image={ContactImage}
      />
      <Container sx={{ mt: 5 }}>
        <Grid
          container
          spacing={4}
          sx={{
            pt: 3,
          }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    fontWeight: FontWeight.bold,
                    color: Colors.charcoalgray,
                    fontFamily: Fontfamily.poppins,
                    FontSize: FontSize.twNine,
                    letterSpacing: "1.5px",
                  }}
                  mb={2}
                >
                  Contact Us
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Message"
                    value={messages}
                    onChange={(e) => setMessages(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                    required
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ mt: 2, letterSpacing: "1px" }}
                    style={{ marginBottom: "5px" }}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card sx={{ height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <LocationOnIcon
                      fontSize="large"
                      sx={{ color: "#f4813f" }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 1,
                        fontWeight: FontWeight.bold,
                        color: Colors.charcoalgray,
                        fontFamily: Fontfamily.poppins,
                        FontSize: FontSize.twenty,
                        letterSpacing: "1.5px",
                      }}
                    >
                      Address
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: Colors.MediumGray,
                        FontSize: FontSize.eighteen,
                        fontFamily: Fontfamily.poppins,
                        letterSpacing: "1px",
                      }}
                    >
                      306A, ARG North Avenue, Road 9, Sikar Road, Jaipur (Raj),
                      India
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <PhoneIcon fontSize="large" sx={{ color: "#f4813f" }} />
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 1,
                        fontWeight: FontWeight.bold,
                        color: Colors.charcoalgray,
                        fontFamily: Fontfamily.poppins,
                        FontSize: FontSize.twenty,
                        letterSpacing: "1.5px",
                      }}
                    >
                      Phone
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: Colors.MediumGray,
                        FontSize: FontSize.eighteen,
                        fontFamily: Fontfamily.poppins,
                        letterSpacing: "1px",
                      }}
                    >
                      +91-84420 25283, +91-7891555777
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{ height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <MailIcon fontSize="large" sx={{ color: "#f4813f" }} />
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 1,
                        fontWeight: FontWeight.bold,
                        color: Colors.charcoalgray,
                        fontFamily: Fontfamily.poppins,
                        FontSize: FontSize.twenty,
                        letterSpacing: "1.5px",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: Colors.MediumGray,
                        FontSize: FontSize.eighteen,
                        fontFamily: Fontfamily.poppins,
                        letterSpacing: "1px",
                      }}
                    >
                      contact@gatiktech.com
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ mt: 5, pb: 5, textAlign: "center" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: FontWeight.bold,
              color: Colors.charcoalgray,
              fontFamily: Fontfamily.poppins,
              FontSize: FontSize.twenty,
              letterSpacing: "1.5px",
            }}
          >
            Connect with Us
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <IconButton
              href="https://www.linkedin.com/company/gatikai-technologies/home"
              target="_blank"
              // sx={{ color: "#f4813f" }}
              sx= {{color: "#0077B5"}}
            >
              <LinkedInIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
