// export default {
//     heading1: 700,
//     heading2: 600,
//     heading3: 500,
//     heading4: 400,
//     heading5: 300,
//     heading6: 200,
//     Morebolder: 900,
//     body: 400,
//     bold: 700,
//     bolder: 900,
//     light: 300,
//     lighter: 100,
//   };
  

const FontWeight = {
  heading1: 700,
  heading2: 600,
  heading3: 500,
  heading4: 400,
  heading5: 300,
  heading6: 200,
  Morebolder: 900,
  body: 400,
  bold: 700,
  bolder: 900,
  light: 300,
  lighter: 100,
};

export default FontWeight;