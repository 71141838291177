import React from "react";
import {
  Box,
  Container,
  useTheme,
  useMediaQuery,
  styled,
  Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AboutImage from "../assets/images/about.jpg";
import CardContent from "@mui/material/CardContent";
import assets from "../assets";
import PageTitle from "../Components/PageTitle";
import Header from "../Components/Header";
import { Colors, FontSize, FontWeight, Fontfamily } from "../common";
import OurClient from "../Components/OurClient";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const cardsData = [
  {
    id: 1,
    heading: "Our Mission",
    description:
      "Our mission is to empower businesses through innovative technology solutions that drive growth, enhance efficiency, and foster long-term success. We are committed to providing top-notch services that not only meet but exceed our clients’ expectations.",
  },
  {
    id: 2,
    heading: "Our Vision",
    description:
      "Our vision is to be a global leader in IT services and consulting, recognized for our innovative solutions, exceptional service, and unwavering commitment to our clients' success. Thank you for considering GatikAI Technologies as your IT partner. We look forward to collaborating with you and helping your business thrive in the digital age.",
  },
  {
    id: 3,
    heading: "Our Approach",
    description:
      "We believe in a client-centric approach, where understanding our clients’ challenges and goals is paramount. Our team of skilled professionals collaborates closely with clients to develop customized solutions that align with their business objectives. By leveraging the latest technologies and industry best practices, we ensure that our solutions are robust, scalable, and future-proof.",
  },
];

const chooseData = [
  {
    icon: assets.Expertise,
    title: "Expertise",
    description:
      "Our team comprises experienced professionals with deep knowledge and expertise in various domains of IT.",
  },
  {
    icon: assets.innovation,
    title: "Innovation",
    description:
      "We are passionate about innovation and continuously explore new technologies to deliver cutting-edge solutions.",
  },
  {
    icon: assets.Quality,
    title: "Quality",
    description:
      "We are committed to delivering high-quality services that adhere to the highest standards of excellence.",
  },
  {
    icon: assets.CustomerSupport,
    title: "Customer Focus",
    description:
      "Our clients’ success is our success. We prioritize their needs and work tirelessly to ensure their satisfaction.",
  },
  {
    icon: assets.agile,
    title: "Agility",
    description:
      "In a fast-paced digital world, we remain agile, adapting quickly to changing technologies and market dynamics.",
  },
  {
    icon: assets.track,
    title: "Proven Track Record",
    description:
      "With a portfolio of successful projects and satisfied clients, GatikAI has a proven track record of delivering results that make a difference.",
  },
];

const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const IconBox = styled(Box)({
    display: "block",
    background: "#FFF",
    color: "#fff",
    padding: "18px 18px",
    borderRadius: "50px",
    width: "50px",
    height: "50px",
    float: "inline-start",
    position: "absolute",
    left: 18,
    border: "1px solid #f4f4f4",
    boxShadow: "1px 2px 2px rgba(255, 255, 255) 2px 2px rgba(255, 255, 255)",
  });

  const features = [
    {
      title: "Custom Software Development",
      description:
        "Crafting bespoke software solutions designed to streamline operations and boost productivity.",
    },
    {
      title: "Mobile Application Development",
      description:
        "Creating intuitive and engaging mobile apps that enhance customer experience and drive business growth.",
    },
    {
      title: "Web Development and Optimization",
      description:
        "Designing and optimizing websites to improve user experience, mobile responsiveness, and search engine visibility.",
    },
    {
      title: "Cloud Solutions",
      description:
        "Offering scalable cloud computing solutions to meet the evolving needs of modern businesses.",
    },
    {
      title: "AI and Machine Learning",
      description:
        "Integrating advanced AI and ML technologies to transform data into actionable insights and automate complex processes.",
    },
    {
      title: "IT Consulting",
      description:
        "Providing strategic IT consulting services to help businesses navigate the digital landscape and achieve their technological goals.",
    },
  ];

  return (
    <>
      <PageTitle title="About Us | GatikAI Tech" />
      <Navbar />
      <Header
        title="About GatikAI Technologies"
        description=" Welcome to GatikAI Technologies, where innovation meets
                excellence in the realm of IT services and consulting. Founded
                in [2021], GatikAI Technologies has swiftly become a trusted
                partner for businesses across various industries, delivering
                cutting-edge digital solutions tailored to meet the unique needs
                of each client."
        image={AboutImage}
      />
      <Grid container sx={{ pt: 5 }}>
        {cardsData.map((item, index) => (
          <Grid
            item
            spacing={3}
            xs={12}
            sm={12}
            md={4}
            key={item.id}
            sx={{ mt: 3 }}
          >
            <Box
              sx={{ height: "90%", borderRadius: "10px" }}
              className="homeCard"
            >
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 3,
                  mb: 2,
                  fontWeight: FontWeight.heading2,
                  fontSize: FontSize.twFour,
                  color: Colors.charcoalgray,
                  letterSpacing: "1.5px",
                }}
              >
                {item.heading}
              </Typography>
              <Typography
                // variant="body2"
                sx={{
                  lineHeight: "1.6rem",
                  letterSpacing: "1.5px",
                  marginTop: "10px",
                  color: Colors.MediumGray,
                  fontFamily: Fontfamily.poppins,
                }}
              >
                {item.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Why Choose Us Section Start */}
      <Box sx={{ pt: 10 }}>
        <Box sx={{ p: 5, backgroundColor: "#f9fafa" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                fontWeight: FontWeight.bold,
                fontSize: FontSize.twNine,
                Fontfamily: Fontfamily.poppins,
                color: Colors.charcoalgray,
                letterSpacing: "1.5px",
              }}
              gutterBottom
            >
              Why Choose Us
            </Typography>
            <Typography
              paragraph
              sx={{
                textAlign: "center",
                Fontfamily: Fontfamily.poppins,
                color: Colors.MediumGray,
                letterSpacing: "1px",
              }}
            >
              Experience unparalleled expertise, innovative solutions, and a
              steadfast commitment to your success.
            </Typography>
          </Box>
          <Grid container spacing={2} sx={{ pt: 3 }}>
            {chooseData.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} mb={2}>
                <Box sx={{ position: "relative", mb: 5 }}>
                  <IconBox>
                    <Typography>
                      <img
                        src={feature.icon}
                        alt={feature.title}
                        style={{
                          width: "50px",
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </Typography>
                  </IconBox>
                </Box>
                <Box>
                  <Card
                    sx={{
                      // height: "175px",
                      height: {xs: "auto",md:"280px", lg: "175px"},
                      p: 1,
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent sx={{ mt: { xs: 0, md: 1, lg: 2 } }}>
                      <Grid
                        container
                        spacing={-2}
                        rowSpacing={-8}
                        columnSpacing={12}
                      >
                        <Grid item xs>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: FontWeight.bold,
                              mb: 2,
                              mt: { xs: 3, md: 2 },
                              letterSpacing: "1.5px",
                              Fontfamily: Fontfamily.poppins,
                              color: Colors.charcoalgray,
                            }}
                          >
                            {feature.title}
                          </Typography>
                          <Typography
                            sx={{
                              Fontfamily: Fontfamily.poppins,
                              color: Colors.MediumGray,
                              letterSpacing: "1px",
                            }}
                          >
                            {feature.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {/* Why Choose Us Section End */}

      {/*  What we do Section Start */}
      <Box sx={{ m: 5, mt: 10, pb: 5 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: FontWeight.bold,
              fontSize: FontSize.twNine,
              color: Colors.charcoalgray,
              fontFamily: Fontfamily.poppins,
              letterSpacing: "1.5px",
            }}
          >
            What we do
          </Typography>
          <Typography
            sx={{
              fontSize: FontSize.sixteen,
              fontFamily: Fontfamily.poppins,
              color: Colors.MediumGray,
              textAlign: "center",
              letterSpacing: "1.5px",
              // pt: 2,
            }}
          >
            At GatikAI Technologies, we specialize in a broad range of IT
            services
          </Typography>
        </Box>
        <Container>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {features.map((feature, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                key={index}
                sx={{
                  mb: { xs: 2, sm: 3, md: 2 },
                  display: "flex",
                  justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    backgroundColor: "#f4f4f4",
                    // color:"#FFF",
                    borderRadius: "10px",
                    transition: "transform 0.5s",
                    "&:hover": {
                      transform: "translateY(-12px)",
                      // backgroundColor: "#fd7e14",
                      // color: "#FFF",
                    },
                    width: { xs: "100%", sm: "100%", md: "80%" },
                  }}
                >
                  <CardContent sx={{ mt: { xs: 0, md: 1, lg: 2 } }}>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <Typography
                          sx={{
                            fontWeight: FontWeight.bold,
                            fontFamily: Fontfamily.poppins,
                            letterSpacing: "1.5px",
                          }}
                        >
                          {feature.title}
                        </Typography>
                        <Typography
                          sx={{
                            letterSpacing: "1px",
                            fontFamily: Fontfamily.poppins,
                            color: Colors.MediumGray,
                          }}
                        >
                          {feature.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      {/*  What we do Section End */}

      <OurClient />

      <Footer />
    </>
  );
};

export default About;
