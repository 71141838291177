import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IndustriesServe from "../Components/IndustriesServe";
import DesignDevelopment from "../Components/DesignDevelopment";
import OurFeatures from "../Components/OurFeatures";
// import "../stylesheets/Home.css";
import WorkProcess from "../Components/WorkProcess";
import StaffImage from "../assets/images/HomePage.jpg";
import PageTitle from "../Components/PageTitle";
import OurClient from "../Components/OurClient";
import Header from "../Components/Header";
import Blog from '../Components/Blog';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

export default function Home() {
  return (
    <>
    <Navbar />
      <PageTitle title="GatikAI Tech | Website & App Development Company" />
      <Header
        title="Fulfill your business needs with Innovative Tech Solutions"
        description="At GatikAI Technologies, we provide top-tier IT services with tailored solutions to meet your unique business needs. Whether you're an early-stage startup or an established large business, our expertise and support can help you achieve your goals and drive growth."
        image={StaffImage}
      />
      <DesignDevelopment />
      <IndustriesServe />
      <WorkProcess />
      <OurFeatures />
      <OurClient />
      <Footer />
      {/* <Blog /> */}
    </>
  );
}
